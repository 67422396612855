<template>
<v-app>
    <div class="reserve-form" style="margin-top:150px">
        <div v-if="alert" class="alert alert-danger alert-position" role="alert">
                {{text}}
            </div>
        <h3 class="reserv-title mb-5">Log In Form </h3>
            <v-form
                ref="form"
                @submit="signIn"
                v-model="valid"
                :lazy-validation="lazy"
                class="pa-3"
            >

            <v-text-field
                v-model="user.email"
                :rules="[
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'Email must be valid',]"
                label="Email"
                outlined
                dense
                required
            ></v-text-field>

            <v-text-field
                v-model="user.password"
                :rules="[v => !!v || 'Password is required',v => v.length >= 6 || 'Min 6 characters']"
                label="Password"
                type="password"
                required
                dense
                outlined
            ></v-text-field>
                                
            <!-- <div class="checkbox">
                <v-checkbox
                    class="mb-5"
                    color="info"
                    value="value"
                    hide-details
                > </v-checkbox>
                <span class="position">Remember Me</span>
            </div> -->
            
            <v-btn
                small
                block
                :disabled="!valid"
                style="color:#fff"
                color="deep-orange darken-2"
                @click="signIn()"
                :loading="loading"
            >
                Log In
            </v-btn>
            <p class="float-left mt-2 f-z"><router-link :to="{name: 'signUp'}"> Don't have an account ? <span style="color:#e54c2a">Get Registered</span> </router-link></p>
            <p class="f-pass-style"><router-link :to="{name: 'passwordReset'}"> <span style="color:#e54c2a">Forgot Password?</span> </router-link></p>
        </v-form>
        <notifications group="signin" position="top left" />
    </div>
</v-app>
</template>

<script>
    export default {
        name: "signIn",
        
        data() {
            return {
                lazy: false,
                alert: false,
                text: '',
                valid: true,
                loading: false,
               user:{
                   email: '',
                   password: ''
               },
               products:this.$store.state.cart
               
            }
        },

        computed:{
            total: function(){
                return this.$store.state.cart.length
            }
        },
        methods: {
               
            signIn(){
               this.loading = true
                this.axios.post('/login',{email:this.user.email,password:this.user.password})
                .then(response=>{
                     let logRes=response.data.success;
                     if(logRes == false)
                         {
                            this.text =response.data.message
                            this.alert = true
                            // this.refs.form.reset()
                            this.loading = false
                         }

                      else{
                        this.alert = true
                        this.$notify({
                            group: 'signin',
                            type: 'success',
                            title: 'You are Logged in',
                        }); 
                        localStorage.setItem('userToken',response.data.data.token);
                        localStorage.setItem('fk_uses_id',response.data.data.user.id);
                        this.$store.commit('setUserAfterLogIN')
                        
                        this.loading = false
                            if(this.total>0)
                            {
                            setTimeout(() => {  this.$router.push({name:"cart.details"}); }, 2000);
                            //this.$router.push({name:'cart.details'});
                            }
                            else if(this.total<0)
                            {
                                //this.$router.push({name:'home'});
                                setTimeout(() => {  this.$router.push({name:"Home"}); }, 2000);
                            }
                            else{
                                //this.$router.push({name:'home'});
                                setTimeout(() => {  this.$router.push({name:"Home"}); }, 2000);
                            }
                           
                      }

                })
            }


        },
        mounted() {
            var el = document.querySelector(".v-application--wrap");
            el.classList.add("mystyle2");
        }
    }
</script>

<style scoped>
.mystyle2 {
    min-height: 0vh!important;
    margin-top: 0px !important;
}
    .router-link-exact-active {
        color: #dbb664 !important;
    }
   
    .alert-position{
        position: absolute;
        top: 21px;
    }
    .theme--light.v-application {
    background: transparent;
        color: rgba(0, 0, 0, 0.87);
    }
    .f-z{
        font-size: 14px;
    }
    .f-pass-style{
        float: right;
        margin-top: 8px;
        font-size: 14px;
    }
    @media only screen and (max-width: 400px){
        .f-pass-style {
            float: left;
            margin-top: 5px;
        }
    }
    @media only screen and (max-width: 768px){
        .reserv-title {
            margin-top: 10px;
        }
    }
</style>